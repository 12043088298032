// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Bg5jdNKW6"];

const variantClassNames = {Bg5jdNKW6: "framer-v-1v8sidl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, newTab, title, width, ...props}) => { return {...props, aXCrMEolz: link ?? props.aXCrMEolz, MSrB9OMT0: title ?? props.MSrB9OMT0 ?? "View", VelfJJTC5: newTab ?? props.VelfJJTC5} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MSrB9OMT0, aXCrMEolz, VelfJJTC5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Bg5jdNKW6", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Ozl1u", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={aXCrMEolz} openInNewTab={VelfJJTC5} smoothScroll={false}><motion.a {...restProps} className={`${cx("framer-1v8sidl", className)} framer-dt3z1s`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Bg5jdNKW6"} ref={ref} style={{backgroundColor: "var(--token-4321a524-8651-4268-85f9-e884d8cb6223, rgb(253, 251, 246))", borderBottomLeftRadius: 500, borderBottomRightRadius: 500, borderTopLeftRadius: 500, borderTopRightRadius: 500, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7Wm9kaWFrLWl0YWxpYw==", "--framer-font-family": "\"Zodiak\", sans-serif", "--framer-font-size": "14px", "--framer-font-style": "italic", "--framer-letter-spacing": "0px", "--framer-line-height": "110%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>View</motion.p></React.Fragment>} className={"framer-1gops5x"} fonts={["FS;Zodiak-italic"]} layoutDependency={layoutDependency} layoutId={"GPXIvi2Va"} style={{"--extracted-r6o4lv": "var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(218, 197, 167))", "--framer-paragraph-spacing": "0px"}} text={MSrB9OMT0} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Ozl1u [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ozl1u .framer-dt3z1s { display: block; }", ".framer-Ozl1u .framer-1v8sidl { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 90px; justify-content: center; overflow: visible; padding: 10px 10px 10px 10px; position: relative; text-decoration: none; width: 90px; }", ".framer-Ozl1u .framer-1gops5x { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Ozl1u .framer-1v8sidl { gap: 0px; } .framer-Ozl1u .framer-1v8sidl > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-Ozl1u .framer-1v8sidl > :first-child { margin-top: 0px; } .framer-Ozl1u .framer-1v8sidl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"MSrB9OMT0":"title","aXCrMEolz":"link","VelfJJTC5":"newTab"}
 */
const Framerqfvg0JBR_: React.ComponentType<Props> = withCSS(Component, css, "framer-Ozl1u") as typeof Component;
export default Framerqfvg0JBR_;

Framerqfvg0JBR_.displayName = "View More Button";

Framerqfvg0JBR_.defaultProps = {height: 90, width: 90};

addPropertyControls(Framerqfvg0JBR_, {MSrB9OMT0: {defaultValue: "View", displayTextArea: false, title: "Title", type: ControlType.String}, aXCrMEolz: {title: "Link", type: ControlType.Link}, VelfJJTC5: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(Framerqfvg0JBR_, [{family: "Zodiak", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/qfvg0JBR_:default", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/YFLZBMMI3DYRSZHBNXVNB72OCINBINIT/AJUISU4RPSUOUSQPHGNMFKZ4M6TTQGWF/HUKQ64PZXOIVT5LHOHNUDCYHXBK5OR45.woff2"}, style: "italic", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/YFLZBMMI3DYRSZHBNXVNB72OCINBINIT/AJUISU4RPSUOUSQPHGNMFKZ4M6TTQGWF/HUKQ64PZXOIVT5LHOHNUDCYHXBK5OR45.woff2", weight: "400"}])